import * as React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

import HeroImage from '../images/IMG_1007.jpg';

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <main>
      <div
        className="wrapper-body"
        style={{ backgroundColor: '#fff' }}
        id="why-use-e1"
      >
        <section
          id="intro"
          style={{ height: '600px', backgroundImage: `url(${HeroImage})` }}
        >
          <div className="container">
            <div
              className="col-md-12"
              style={{ paddingTop: '120px', paddingBottom: '100px' }}
            >
              <h1>Privacy Policy</h1>
            </div>
          </div>
        </section>

        <div className="padding-topbottom-50 main-text">
          <h3>What information do we collect?</h3>
          <p>
            We collect information from you when you register on our site, place
            an order, or fill out a form.
          </p>
          <p>
            When ordering or registering on our site, as appropriate, you may be
            asked to enter your: name, e-mail address, mailing address, phone
            number, or credit card information. You may, however, visit our site
            anonymously.
          </p>

          <h3 className="margin-top-50">
            What do we use your information for?
          </h3>
          <p>
            Any of the information we collect from you may be used in one of the
            following ways:
          </p>
          <ul>
            <li>
              To personalize your experience (your information helps us to
              better respond to your individual needs)
            </li>
            <li>
              To improve our website - (we continually strive to improve our
              website offerings based on the information and feedback we receive
              from you)
            </li>
            <li>
              To improve customer service - (your information helps us to more
              effectively respond to your customer service requests and support
              needs)
            </li>
            <li>To process transactions</li>
            <li>
              Your information, whether public or private, will not be sold,
              exchanged, transferred, or given to any other company for any
              reason whatsoever, without your consent, other than for the
              express purpose of delivering the purchased product or service
              requested.
            </li>
            <li>To send periodic emails</li>
            <li>
              The email address you provide for order processing, may be used to
              send you information and updates pertaining to your order, in
              addition to receiving occasional company news, updates, related
              product or service information, etc.
            </li>
          </ul>

          <h3 className="margin-top-50">How do we protect your information?</h3>
          <p>
            We implement a variety of security measures to maintain the safety
            of your personal information when you place an order or enter,
            submit, or access your personal information.
          </p>
          <p>
            We offer the use of a secure server. All supplied sensitive/credit
            information is transmitted via Secure Socket Layer (SSL) technology
            and then encrypted into our Payment gateway providers database only
            to be accessible by those authorized with special access rights to
            such systems, and are required to keep the information confidential.
          </p>
          <p>
            After a transaction, your private information (credit cards, social
            security numbers, financials, etc.) will not be stored on our
            servers.
          </p>

          <h3 className="margin-top-50">Do we use cookies?</h3>
          <p>
            Yes (Cookies are small files that a site or its service provider
            transfers to your computers hard drive through your Web browser (if
            you allow) that enables the sites or service providers systems to
            recognize your browser and capture and remember certain information.
          </p>
          <p>
            We use cookies to help us remember and process the items in your
            shopping cart.
          </p>

          <h3 className="margin-top-50">
            Do we disclose any information to outside parties?
          </h3>
          <p>
            We do not sell, trade, or otherwise transfer to outside parties your
            personally identifiable information. This does not include trusted
            third parties who assist us in operating our website, conducting our
            business, or servicing you, so long as those parties agree to keep
            this information confidential. We may also release your information
            when we believe release is appropriate to comply with the law,
            enforce our site policies, or protect ours or others rights,
            property, or safety. However, non-personally identifiable visitor
            information may be provided to other parties for marketing,
            advertising, or other uses.
          </p>

          <h3 className="margin-top-50">Third party links</h3>
          <p>
            Occasionally, at our discretion, we may include or offer third party
            products or services on our website. These third party sites have
            separate and independent privacy policies. We therefore have no
            responsibility or liability for the content and activities of these
            linked sites. Nonetheless, we seek to protect the integrity of our
            site and welcome any feedback about these sites.
          </p>

          <h3 className="margin-top-50">Online Privacy Policy Only</h3>
          <p>
            This online privacy policy applies only to information collected
            through our website and not to information collected offline.
          </p>

          <h3 className="margin-top-50">Your Consent</h3>
          <p>By using our site, you consent to our privacy policy.</p>

          <h3 className="margin-top-50">Changes to our Privacy Policy</h3>
          <p>
            If we decide to change our privacy policy, we will post those
            changes on this page, and/or update the Privacy Policy modification
            date below.
          </p>
        </div>
      </div>
    </main>
  </Layout>
);

export default PrivacyPage;
